import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import duoCom from "@svt/duo-communication";
import * as Sentry from "@sentry/react";

import MultiChoiceOverview from "./multiChoiceOverview";
import MultiChoiceQuestion from "./multiChoiceQuestion";
import {
    QUESTIONS_SET_CORRECT_ANSWER,
    MULTI_CHOICE_INITIAL,
} from "../../../redux/actions";
import pointsSender from "../../../helpers/pointsSender";
import { getQuestionIndex } from "../../../helpers/questionHelpers";

function MultiChoice(props) {
    //Trying to fix ([PS] [FE] Felsöka vad som gör att vyer i spelet ibland inte visas) - https://trello.com/c/ihiOPlHj
    useEffect(() => {
        if (duoCom.settings?.("appEnvironment") === "stage") {
            Sentry.captureMessage(`MultiChoice props ${JSON.stringify(props)}`);
        }
    }, [props]);

    const questionState = useSelector(
        (state) => state.questions[props.element.id],
    );
    const multiChoiceState = useSelector((state) => state.multiChoice);
    const dispatch = useDispatch();

    const _getQuestions = useCallback(() => {
        // Let's make an array of this flat structure crap
        let questions = [];

        if (props.element.question_1) {
            questions.push({
                question: props.element.question_1,
                image: props.element.image_1,
                options: props.element.options_1,
                requiresOneCorrect:
                    props.element.requires_one_correct_question_1,
            });
        }

        if (props.element.question_2) {
            questions.push({
                question: props.element.question_2,
                image: props.element.image_2,
                options: props.element.options_2,
                requiresOneCorrect:
                    props.element.requires_one_correct_question_2,
            });
        }

        if (props.element.question_3) {
            questions.push({
                question: props.element.question_3,
                image: props.element.image_3,
                options: props.element.options_3,
                requiresOneCorrect:
                    props.element.requires_one_correct_question_3,
            });
        }

        return questions;
    }, [
        props.element.question_1,
        props.element.question_2,
        props.element.question_3,
        props.element.image_1,
        props.element.options_1,
        props.element.requires_one_correct_question_1,
        props.element.image_2,
        props.element.options_2,
        props.element.requires_one_correct_question_2,
        props.element.image_3,
        props.element.options_3,
        props.element.requires_one_correct_question_3,
    ]);

    const questionsRef = useRef(_getQuestions());
    const _setCorrectAnswer = useCallback(() => {
        if (questionState.correctAnswer === null) {
            let allCorrectAnswers = [];

            for (let question of questionsRef.current) {
                const options = question.options;
                let questionCorrectAnswers = [];

                for (let option of options) {
                    if (option.is_correct) {
                        questionCorrectAnswers.push(option.option);
                    }
                }

                allCorrectAnswers.push(questionCorrectAnswers);
            }

            dispatch({
                type: QUESTIONS_SET_CORRECT_ANSWER,
                id: props.element.id,
                correctAnswer: allCorrectAnswers,
            });
        }
    }, [dispatch, props.element.id, questionState.correctAnswer]);

    const _getInitial = useCallback(() => {
        let initialArray = [];

        for (let i = 0; i < questionsRef.current.length; i++) {
            let options = [];
            let amountToSelect = 0;

            let requiresOneCorrectFlag =
                questionsRef.current[i].requiresOneCorrect;

            for (let option of questionsRef.current[i].options) {
                options.push({
                    option: option.option,
                    isCorrect: Boolean(option.is_correct),
                    isSelected: false,
                });

                if (requiresOneCorrectFlag) {
                    amountToSelect = 1;
                } else if (option.is_correct) {
                    amountToSelect++;
                }
            }

            initialArray.push({
                options: options,
                amountSelected: 0,
                amountToSelect: amountToSelect,
                requiresOneCorrectFlag,
            });
        }

        return initialArray;
    }, []);

    const _setInitial = useCallback(() => {
        if (multiChoiceState.id !== props.element.id) {
            dispatch({
                type: MULTI_CHOICE_INITIAL,
                data: _getInitial(),
                id: props.element.id,
            });
        }
    }, [_getInitial, dispatch, multiChoiceState.id, props.element.id]);

    useEffect(() => {
        _setInitial();
        _setCorrectAnswer();

        return () => {
            pointsSender.sendOne(props.element.id, getQuestionIndex());
        };
    }, [_setCorrectAnswer, _setInitial, props.element.id]);

    const { element } = props;
    let content;

    if (!multiChoiceState.isInitialized) {
        return null;
    }

    if (questionsRef.current.length === 1) {
        content = (
            <MultiChoiceQuestion
                index={0}
                element={element}
                question={questionsRef.current[0]}
                moreThanOneQuestion={false}
            />
        );
    } else if (typeof questionState.beginAnswer === "number") {
        content = (
            <MultiChoiceQuestion
                index={questionState.beginAnswer}
                element={element}
                question={questionsRef.current[questionState.beginAnswer]}
                moreThanOneQuestion
            />
        );
    } else {
        content = (
            <MultiChoiceOverview
                element={element}
                questions={questionsRef.current}
                questionState={questionState}
                dispatch={dispatch}
            />
        );
    }

    return <div className="svt_mc">{content}</div>;
}

export default MultiChoice;

MultiChoice.propTypes = {
    element: PropTypes.object.isRequired,
};
