import React from "react";
import PropTypes from "prop-types";
import duoCom from "@svt/duo-communication";
import { analytics, reportErrorToSentry } from "@svt/duo-shared-components";

import ShareImg from "../../assets/icons/share.svg?react";
import shareImageEncoded from "../../assets/inline/share-image.jpg";
import shareImageEncodedNoStars from "../../assets/inline/share-image-no-stars.jpg";

export default class Share extends React.Component {
    constructor() {
        super();

        this.state = {
            shareImg: null,
        };

        this.canvasRef = React.createRef();
        this._shareMisc = this._shareMisc.bind(this);
    }

    componentDidMount() {
        this._drawCanvas();
    }

    componentWillUnmount() {
        clearTimeout(this.drawTimeout);
    }

    _shareMisc() {
        if (duoCom.supports("shareToMisc")) {
            duoCom.shareToMisc({
                imageData: this.state.shareImg.split(",")[1],
            });
        }

        analytics.trackClickEvent("dela");
    }

    _drawCanvas() {
        clearTimeout(this.drawTimeout);
        let image = new Image();

        image.onload = () => {
            if (this.canvasRef && this.canvasRef.current) {
                let ctx = this.canvasRef.current.getContext("2d");

                ctx.drawImage(image, 0, 0);

                /*
                    There is a bug with custom fonts in canvas on iOS 10.3
                    Solution: first paint an empty text, then wait a second and paint the real text
                    https://forums.developer.apple.com/thread/74980
                */
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "rgb(254, 253, 217)";
                ctx.font = "900 130px Publik";
                ctx.fillText("", 0, 0);

                this.drawTimeout = setTimeout(() => {
                    if (this.canvasRef && this.canvasRef.current) {
                        // Score
                        ctx.fillText(this.props.score, 500, 380);

                        // Stars
                        ctx.font = "900 40px Publik";
                        if (this.props.stars) {
                            ctx.fillText(this.props.stars, 500, 560);
                        }

                        this.setState({
                            shareImg: this.canvasRef.current.toDataURL(),
                        });
                    }
                }, 1000);
            }
        };

        image.onerror = function (error) {
            reportErrorToSentry(error);
        };

        image.src = `${
            this.props.stars ? shareImageEncoded : shareImageEncodedNoStars
        }`;
    }

    render() {
        const shareImageNotLoaded = this.state.shareImg === null;

        return (
            <div className="svt_share">
                <canvas
                    className="svt_share__share-canvas"
                    width="1000"
                    height="1000"
                    ref={this.canvasRef}
                />

                <button
                    type="button"
                    className="svt_share__share-button"
                    disabled={shareImageNotLoaded}
                    onClick={this._shareMisc}
                    aria-label="Dela"
                >
                    <div className="svt_share__share-content-wrapper">
                        <ShareImg />
                        <span className="svt_share__share-text">Dela</span>
                    </div>
                </button>
            </div>
        );
    }
}

Share.propTypes = {
    score: PropTypes.number.isRequired,
    stars: PropTypes.number,
};
