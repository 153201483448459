import PropTypes from "prop-types";
import ldIsEqual from "lodash/isEqual";

import AnswerBoxMultiChoice from "./answerBox/answerBoxMultiChoice";

export default function QuestionAnswersMultiChoice({ question, customFields }) {
    const { correctAnswer } = question;

    function getIsCorrect({ index, requiresOneCorrectFlag }) {
        if (requiresOneCorrectFlag && question.answer[index]) {
            return question.correctAnswer[index].some((answer) =>
                question.answer[index].includes(answer),
            );
        } else {
            return ldIsEqual(
                question.answer[index],
                question.correctAnswer[index],
            );
        }
    }

    return (
        <div className="svt_results-mc">
            <ul className="svt_results-mc__list">
                {correctAnswer.map((singleCorrectAnswer, index) => {
                    const requiresOneCorrectFlag =
                        customFields[
                            `requires_one_correct_question_${index + 1}`
                        ];

                    return (
                        <li key={index} className="svt_results-mc__list-item">
                            <AnswerBoxMultiChoice
                                answer={
                                    question.answer
                                        ? question.answer[index]
                                        : null
                                }
                                requiresOneCorrectFlag={requiresOneCorrectFlag}
                                correctAnswer={singleCorrectAnswer}
                                isCorrect={
                                    question.answer
                                        ? getIsCorrect({
                                              index,
                                              requiresOneCorrectFlag,
                                          })
                                        : false
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

QuestionAnswersMultiChoice.propTypes = {
    question: PropTypes.object.isRequired,
    customFields: PropTypes.object.isRequired,
};
